import styled from 'styled-components'
import media from 'styled-media-query'

import transitions from '../../styles/transitions'

export const LayoutWrapper = styled.section`
  margin: 0 auto;
  max-width: 850px;
  background: var(--background);
`

export const LayoutMain = styled.main`
  background: var(--mainBackground);
  transition: ${transitions.DEFAULT};
  width: 100%;

  body#card & {
    grid-template-areas:
      'cards'
      'pagination';
  }

  ${media.lessThan('large')`
    padding: 0 0 3rem 0;
  `}
`
