import styled from 'styled-components'
import media from 'styled-media-query'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import transitions from '../../styles/transitions'

export const MenuLinksWrapper = styled.nav`
  text-align: center;
`
export const MenuLinksBrand = styled(AniLink)`
  color: var(--texts);
  text-decoration: none;
  transition: ${transitions.COLOR};

  body#card & {
    background-color: var(--background);
  }

  &:hover {
    box-shadow: inset 0 -3px 0 var(--highlight);
  }

  &.active {
    box-shadow: inset 0 -3px 0 var(--highlight);
  }

  padding: 0.55rem 0;

  font-weight: bold;
  display: inline-block;
  font-size: 0.9rem;
  margin-right: 20px;
`
export const MenuLinksList = styled.ul`
  font-size: 0.9rem;
  font-weight: 300;
  display: inline-block;
`

export const MenuLinksItem = styled.li`
  padding: 0.5rem 0;
  display: inline-block;
  margin: 0 10px;

  .active {
    box-shadow: inset 0 -3px 0 var(--highlight);
  }

  a {
    color: var(--menuTexts);
    text-decoration: none;
    transition: ${transitions.COLOR};
    padding: 6px 0;

    &:hover {
      box-shadow: inset 0 -3px 0 var(--highlight);
    }
  }
`
