import styled from 'styled-components'
import media from 'styled-media-query'

import transitions from '../../styles/transitions'

export const HeaderContainer = styled.header`
  align-items: center;
  background: var(--mainBackground);
  transition: ${transitions.ALL};
  margin-top: 40px;
`
